<template>
  <div>
    <b-input
      placeholder="Filtrar pedido, moto, bairro cliente"
      class="p-3"
      autocomplete="off"
      v-model="filtro"
    />
    <div class="geral">
      <div
        class="moto"
        v-for="controle in filtrado"
        :key="controle.cod_controle"
      >
        <div
          class="titulo"
          :class="{
            bgEmRota: controle.emRota > 0,
            bgConcluida:
              (controle.cod_entrada && !controle.emRota) || controle.emRota <= 0
          }"
        >
        <img src="@/assets/img/icones/menu/motorcycle_delivery_multiple_boxes_40px.png" height="35" />
        <br/>
          {{  controle.info && controle.info.nome? controle.info.nome : controle.nome }}
          <small v-if="controle.info && controle.info.nome">
            ({{controle.nome}})
          </small>
        </div>
        <div class="pedidos">
          <div
            v-for="ped in controle.entregas"
            :key="ped.cod_pedido"
            :class="{ bgEmRota: ped.status == 3, bgConcluida: ped.status == 4 }"
            @click="openPedido(ped)"
          >
            {{ ped.senha }} - {{ ped.cliente.Bairro }}
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-detalhe-ped"
      hide-footer
      title="Detalhe da entrega"
      @hide="pedidoSelecionado = null"
    >
      <div v-if="pedidoSelecionado">
        <h5>{{ pedidoSelecionado.senha }} - {{ pedidoSelecionado.empresa }}</h5>
        <hr />
        <b>Data/Hora: </b> {{pedidoSelecionado.data | moment('DD/MM/YYYY HH:mm:ss')}} <br/>
        <b>Cliente: </b> {{ pedidoSelecionado.cliente.Nome }} <br />
        <b>Endereço: </b> {{pedidoSelecionado.cliente.Logradouro}}
         {{pedidoSelecionado.cliente.complemento?', ' + pedidoSelecionado.cliente.complemento:''}} <br />
         <b>Bairro:  {{pedidoSelecionado.cliente.Bairro}}</b><br/>
        <b>Telefone: </b> {{ pedidoSelecionado.cliente.Telefone }} <br />

         
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    empresa: Object,
    data: Date
  },
  sockets: {
    connect() {
      // console.log('conectado motos');
      this.getControles();
    },
    motoControle(result) {
      this.controles = result.filter(x => x.cod_entrada);
      this.$socket.emit("entregas:lista", {
        tipo: "buscar",
        data: moment(this.data).format("YYYY-MM-DD"),
        cod_empresa: this.empresa.cod_empresa
      });
      this.filtrar();
    },
    listaEntregas(res) {
      // console.log(res);
      for (let controle of this.controles) {
        controle.entregas = res
          .map(it => {
            return {
              ...it,
              senha: "#" + it.senha
            };
          })
          .filter(x => x.cod_moto == controle.cod_moto);
      }

      this.$forceUpdate();
      this.filtrar();
    },
    solicitaMotoControle() {
      this.getControles();
    }
  },
  mounted() {
    // console.log("sock", this.$socket)
    this.getControles();
  },
  data() {
    return {
      controles: [],
      motoSelecionada: null,
      controlesShow: [],
      filtro: "",
      pedidoSelecionado: null
    };
  },
  watch: {
    data: function() {
      this.getControles();
    },
    empresa: function() {
      this.getControles();
    }
  },
  computed: {
    filtrado: function() {
      // let controlesShow = JSON.parse(JSON.stringify(this.controles));
      let controlesShow = Object.assign([], this.controles);
      const formata = str => {
        return str.trim().toLowerCase();
      };
      const index = str => {
        let str2 = this.filtro;
        return (
          str &&
          str2 &&
          typeof str == "string" &&
          typeof str2 == "string" &&
          formata(str).indexOf(formata(str2)) >= 0
        );
      };
      if (formata(this.filtro) != "") {
        controlesShow = controlesShow.filter(
          x =>
            index(x.nome) ||
            x.entregas.filter(
              e =>
                index(e.senha) ||
                index(e.cliente.Nome) ||
                index(e.cliente.Bairro) ||
                index(e.cliente.Telefone) ||
                index(e.cliente.Complemento) ||
                index(JSON.stringify(e))
            ).length > 0
        );
        // for(let c of controlesShow){
        //   c.entregas = c.entregas.filter(e=>
        //      index(JSON.stringify(e))
        //   )
        // }
        // controlesShow = controlesShow.filter(x=>x.entregas && x.entregas.length>0)
      }
      // console.log(1, controlesShow);
      return controlesShow;
    }
  },
  methods: {
    openPedido(ped) {
      this.pedidoSelecionado = ped;
      this.$bvModal.show("modal-detalhe-ped");
    },
    filtrar() {},
    openMotoControle(moto) {
      this.motoSelecionada = moto;
      this.$bvModal.show("modal-moto");
    },
    closeModalOpenMotos() {
      this.$bvModal.hide("modal-moto");
      this.motoSelecionada = null;
    },
    getControles() {
      // console.log('send',"entregas:motoControle",{empresa:this.empresa})
      this.$socket.emit("entregas:motoControle", {
        data: moment(this.data).format("YYYY-MM-DD"),
        cod_empresa: this.empresa.cod_empresa
      });
    }
  }
};
</script>

<style>
</style>